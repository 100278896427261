@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&family=Red+Hat+Display:wght@300;400&family=Work+Sans:wght@100;200;300;400;500;600;700&display=swap");
body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}

img {
  max-width: 100%;
}

#move3 {
  position: relative;
  z-index: 9999;
}

.mainBox2 {
  padding-top: 60px !important;
}

.heading1 {
  color: #0d8ccd;
  font-size: 16px;
  background-color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.textbox h1 {
  color: red;
}

.textbox h1:hover {
  color: black;
}

.card {
  text-align: center;
  height: 600px;
  width: 100%;
  background: #fff;
  position: relative;
}

.card h1 {
  margin: 0;
}

.card:nth-child(even) {
  background: #808080;
}

.card:nth-child(1) {
  position: fixed;
  top: 0px;
}

.card:nth-child(2) {
  margin-top: 600px;
}

.sticky1 {
  text-align: center;
  height: 600px;
  width: 100%;
  background: #fff;
  position: relative;
}

.animation {
  -webkit-animation: mymove 4s infinite;
          animation: mymove 4s infinite;
}

.animation2 {
  -webkit-animation: mymove 2s infinite;
          animation: mymove 2s infinite;
}

.animation3 {
  -webkit-animation: mymove 6s infinite;
          animation: mymove 6s infinite;
}

.animation4 {
  -webkit-animation: mymove4 4s infinite;
          animation: mymove4 4s infinite;
}

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes mymove {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes mymove4 {
  0% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
  50% {
    -webkit-transform: translate(-50%, -20px);
            transform: translate(-50%, -20px);
  }
  100% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}

@keyframes mymove4 {
  0% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
  50% {
    -webkit-transform: translate(-50%, -20px);
            transform: translate(-50%, -20px);
  }
  100% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #c8ff00;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #859831;
}

.disbaledBox {
  position: relative;
}

.disbaledBox h2 {
  color: #7f7878 !important;
}

.disbaledBox .minted {
  position: absolute;
  top: 10px;
  right: -10px;
  background-color: #6adeea;
  padding: 4px 15px;
  border-radius: 15px;
}

.disbaledBox .minted h2 {
  font-size: 14px;
  color: #000 !important;
  font-weight: 700;
}

.greenBox {
  background-color: #6adeea !important;
}

.greenBox h2 {
  color: #000 !important;
}

.greenBox a {
  color: yellow !important;
}

.greenBox p {
  color: #000 !important;
}

.disbaledBox .minted {
  position: absolute;
  top: -20px;
  left: -10px;
  width: 62px;
  left: 16px;
  background-color: #c8ff00;
  padding: 10px 15px;
  border-radius: 50px;
}

.disbaledBox .minted h2 {
  font-size: 18px;
  color: #000 !important;
  font-weight: 600;
  text-align: center;
  font-family: "Work Sans", sans-serif !important;
}

.slick-next,
.slick-prev {
  z-index: 99;
  font-weight: 800;
  width: 50px;
  height: 50px;
}

.slick-next {
  right: 10px !important;
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}

.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #6adeea;
}

.slick-next:before,
.slick-prev:before {
  content: "›";
}

.slick-next:before,
.slick-prev:before {
  font-size: 56px;
}

.slick-prev {
  left: 10px !important;
}

.slick-next,
.slick-prev {
  z-index: 99;
  font-weight: 800;
  width: 50px;
  height: 50px;
}

.slick-next:before,
.slick-prev:before {
  font-size: 56px;
}

.slick-prev:before {
  content: "‹";
}

.height100,
.slick-list,
.slick-track,
.slick-slide > * {
  height: 100%;
}

.slick-track > * {
  height: 100%;
}

.slick-next:before,
.slick-prev:before {
  line-height: 1;
  opacity: 0.75;
  color: #6adeea;
}

.pl24 {
  /* padding-left: 24px; */
}

.profilebutton {
  font-weight: 600;
  color: #222;
  cursor: pointer;
  font-size: 12px;
  min-width: auto;
  line-height: 14px;
  border-radius: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}

.childDiv::after {
  content: " ";
  position: absolute;
  right: -7px;
  width: 14px;
  height: 54px;
  background-color: #6b32fb;
  -webkit-transform: rotate(17deg);
          transform: rotate(17deg);
  top: -3px;
}

.flexBetween {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

@media (max-width: 991px) {
  .pl24 {
    padding-left: 0px;
    display: contents;
  }
}

@media (max-width: 767px) {
  .faqtextheader {
    font-size: 20px !important;
  }
}

.parentDiv {
  height: 48px;
  width: 100%;
  border-radius: 0 50px 50px 0;
  overflow: hidden;
  border: 1px solid #6b32fb;
  text-align: right;
}

@media (max-width: 600px) {
  .parentDiv {
    border-radius: 50px;
  }
}

.slick-slide > div {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.childDiv {
  height: 48px;
  width: 62.5%;
  opacity: 1;
  background: -webkit-gradient(linear, left top, left bottom, from(#9653ff), to(#5d29ff));
  background: linear-gradient(180deg, #9653ff 0%, #5d29ff 100%);
  border-radius: 0;
  text-align: right;
  position: relative;
}

.childDiv::after {
  content: " ";
  position: absolute;
  right: -9px;
  width: 47px;
  height: 72px;
  background: -webkit-gradient(linear, left top, left bottom, from(#9653ff), to(#5d29ff));
  background: linear-gradient(180deg, #9653ff 0%, #5d29ff 100%);
  -webkit-transform: rotate(17deg);
          transform: rotate(17deg);
  top: -9px;
}

@media (max-width: 600px) {
  .childDiv::after {
    right: -15px;
  }
}

.valueText {
  margin-top: -9px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: black;
  font-weight: 500;
  position: absolute;
  font-family: "Red Hat Display", sans-serif;
  right: 0px;
  font-size: 28px;
  top: 69%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.width100 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
      flex-wrap: inherit;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #6adeea;
}
