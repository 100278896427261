.banner {
  position: relative;
  margin-top: -129px;
  max-width: 2350px;
  overflow: hidden;
}

.banner img {
  width: 100%;
  height: auto;
  display: block;
  margin-top: 150px;

}



.heading-top1 {
  position: absolute;
  top: 40%;
  left: 15%;
  /* transform: translate(-50%, -50%); */
  text-align: left;
  color: #000;
}

.heading-top2 {
  font-size: 16px !important;
  margin-bottom: -20px !important;
  font-weight: 500;
  font-family: "montserrat";
}


.heading-top3 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: rgb(0, 150, 136);
  font-weight: 600;
  font-family: "montserrat";
}


h1 span {
  color: rgb(0, 150, 136);
}

.heading-bottom2 {
  font-size: 20px !important;
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: 400 !important;
  color: #000 !important;
  font-family: "montserrat";
}

@media (min-width: 320px) and (max-width: 475px) {
  .heading-bottom2 {
    display: none;
  }

  .heading-top3 {
    display: none;
  }

  .heading-top2 {
    display: none;
  }

  .banner img {

    display: none;

  }

  .contactus {
    display: none !important;
  }

  h1 {
    margin-top: -75px !important;
    font-size: 25px !important;
    font-family: "montserrat";
    font-weight: 400;
  }
}

@media (min-width: 475px) and (max-width: 768px) {
  .heading-bottom2 {
    display: none;
  }

  .contactus {
    display: none !important;
  }

  .heading-top2 {
    display: none;
  }

  .heading-top3 {
    display: none;
  }
}

.contactus {
  background-color: rgb(0, 150, 136) !important;
  /* or your desired color */
  color: #fff !important;
  width: 180px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .heading-top {
    width: 80%;
  }

  h1 {
    font-size: 2.4rem;
    line-height: normal;
  }


}

@media (min-width: 768px) and (max-width: 1024px) {
  .heading-top1 {
    top: 40%;
    left: 10%;
  }

  .heading-top2 {
    font-size: 10px !important;
    margin-bottom: -20px !important;
    font-weight: 500;
    font-family: "montserrat";
  }

  .heading-top3 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: rgb(0, 150, 136);
    font-weight: 600;
    font-family: "montserrat";
  }
}

@media (min-width: 1035px) and (max-width: 1550px) {
  .heading-top1 {
    top: 40%;
    left: 10%;
  }

  .heading-top2 {
    font-size: 16px !important;
    margin-bottom: -20px !important;
    font-weight: 500;
    font-family: "montserrat";
  }
}

@media (min-width: 991px) and (max-width: 1035px) {
  .heading-top1 {
    top: 39%;
    left: 10%;
  }

  .heading-top2 {
    font-size: 10px !important;
    margin-bottom: -20px !important;
    font-weight: 500;
    font-family: "montserrat";
  }

  .heading-top3 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: rgb(0, 150, 136);
    font-weight: 600;
    font-family: "montserrat";
  }

}