/* Pioneering.css */

.career {
  text-align: start;
  padding: 100px 20px;
  margin: auto;
  justify-content: center;


}

.title {
  font-size: 1.4rem;
  margin-bottom: 20px;
  text-align: center;
  font-family: "poppins";
  font-weight: 500;
  margin: auto;
}

.link-title {

  width: 100%;
  max-width: 70%;

}

.paragraph {
  font-size: 1.2rem;
  margin-bottom: 40px;
  text-align: center;
  font-family: "poppins";
  font-weight: 400;
}

.socialmedia {
  display: flex;
  justify-content: center;
  /* Adjust as needed */
  align-items: center;
  gap: 2rem;
}

.card {
  flex: 0 0 calc(33.3333% - 20px);
  /* Adjust card width, and consider margins */
  margin: 10px;
  text-align: center;
}

.link {
  font-size: 1.2rem;
  margin-top: 10px;
  /* text-align: start; */
  font-family: "poppins";
  font-weight: 500;
}

.link-title {
  font-size: 1.1rem;
  margin-top: 10px;
  font-family: "poppins";
  font-weight: 400;
}

.card-diversity {
  text-align: start;
  padding: 20px;
  /* Adjust as needed */
  border: 1px solid #ddd;
  /* Optional: Add border for visual separation */
  border-radius: 10px;


}

.card-adaptable {
  text-align: start;
  padding: 20px;
  /* Adjust as needed */
  border: 1px solid #ddd;
  /* Optional: Add border for visual separation */
  border-radius: 10px;

}

.card-linkedin {
  text-align: start;
  padding: 20px;
  /* Adjust as needed */
  border: 1px solid #ddd;
  /* Optional: Add border for visual separation */
  border-radius: 10px;
}
@media (min-width: 320px) and (max-width: 375px) {
  .card-linkedin{

    margin-top: 60px;
  }
}
@media (min-width: 375px) and (max-width:425px) {
  .card-linkedin{

    margin-top: 100px;
  }
}




/* Responsive styles */
@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }

  .paragraph {
    font-size: 1rem;
  }

  .card {
    flex: 0 0 calc(50% - 20px);
    /* Adjust card width for smaller screens */
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .socialmedia {
    justify-content:center;
    flex-wrap: wrap;
  }

  .career {
    padding: 0%;
  }

}


@media (min-width: 1224px) and (max-width: 1550px) {


  .title {

    padding-top: 90px !important;
    text-align: center;
    margin: auto;
    font-size: 2.4rem;
  }
}