.login-form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    h2{
        color: #046A38;
        font-size: 40px;
        text-align: left;
    }
}

.login-form {
    display: flex;
    flex-direction: column;
}

.form-control {
    margin-bottom: 20px;
    
}

label {
    font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
    padding: 1px;
    margin-top: 5px;
    font-size: 16px;
    width: 100%;
    
    border-radius: 10px;
   
}

input[type="file"] {
    padding: 10px;
    margin-top: 5px;
    font-size: 16px;
    width: 100%;
   
    border-radius: 10px;
}

.error-message {
    color: red;
}

button[type="submit"] {
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    width: 100%;
    border: none;
    background-color: rgb(0, 150, 136);
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
}
button:hover{
    background-color:  #046A38;
}

@media only screen and (max-width: 600px) {
    .login-form-container {
        max-width: "100%";
        width: "100%";
        padding: 36px;
        
    
    }
}
