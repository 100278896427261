.diversity-container {
    text-align: center;
    margin: 0 auto;
    max-width: 1000px;
    /* Adjust as needed */
    padding: 100px 20px;
    /* Adjust as needed */
}

.diversity-title {
    margin-top: 20px;

}

.diversity-paragraph {
    margin-bottom: 20px;
    text-align: center;

}

.diversity-section {
    display: flex;
    justify-content: center;
    gap: 70px;
}

.diversity-card {
    width: 45%;
    text-align: center;
}

.diversity-link {
    /* Add your styles for links and link titles */
    font-size: 20px;
    font-family: "poppins";
    font-weight: 500;
    text-align: left;
}

.diversity-link-title {
    font-size: 15px;
    font-family: "poppins";
    font-weight: 300;
    text-align: left;
}

@media only screen and (max-width: 768px) {
    .diversity-section {
        flex-direction: column;
        align-items: center;
    }

    .diversity-card {
        width: 80%;
    }
}

@media(min-width: 375px) {
    .diversity-title {
        color: rgb(7, 6, 6);
    }
}