@import "./base";
@import "./responsive";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&family=Red+Hat+Display:wght@300;400&family=Work+Sans:wght@100;200;300;400;500;600;700&display=swap");
.parentDiv {
  height: 48px;
  width: 100%;
  border-radius: 0 50px 50px 0;
  overflow: hidden;
  border: 1px solid #6b32fb;
  text-align: right;
  @media (max-width: 600px) {
    border-radius: 50px;
  }
}
.slick-slide > div {
  // transform: scale(0.5);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.childDiv {
  height: 48px;
  width: 62.5%;
  opacity: 1;
  background: linear-gradient(
    180deg,
    rgba(150, 83, 255, 1) 0%,
    rgba(93, 41, 255, 1) 100%
  );
  border-radius: 0;
  text-align: right;
  position: relative;
  &::after {
    content: " ";
    position: absolute;
    right: -9px;
    width: 47px;
    height: 72px;
    background: linear-gradient(180deg, #9653ff 0%, #5d29ff 100%);
    transform: rotate(17deg);
    top: -9px;
    @media (max-width: 600px) {
      right: -15px;
    }
  }
  // @media (max-width: 600px) {
  //   border-radius: 50px;
  // }
}
.valueText {
  margin-top: -9px;
  align-items: center;
  display: flex;
  color: black;
  font-weight: 500;
  position: absolute;
  font-family: "Red Hat Display", sans-serif;
  right: 0px;
  font-size: 28px;
  top: 69%;
  transform: translate(-50%, -50%);
}
.width100 {
  width: 100%;
  display: flex;
  flex-wrap: inherit;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #6adeea;
}
