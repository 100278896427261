@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 992px) {
  .pl24 {
    padding-left: 0px;
  }
}
@media (max-width: 767px) {
  .faqtextheader {
    font-size: 20px !important;
  }
}
