.pathway-container {
    /* text-align: center; */
    margin: 0 auto;
    max-width: 954px; /* Adjust as needed */
    padding: 0px 20px; /* Adjust as needed */
    
}

.pathway-title {
    margin-top: 20px;
    text-align: center;
    
}
.pathway-paragraph{
    font-size: 20px;
    text-align: center;
}
.pathway-heading{
    font-size: 25px;
    text-align: justify;
    font-family: 'Poppins';
    font-weight: 400;
    margin-top: 130px;
 
}
.pathway-icon{
    display: flex;
    gap: 40px;
    padding: 0%;
    
}
.pathway-headind-title{
    
    text-align: justify;
    font-family: 'Poppins';
    font-weight: 400;

}
.pathway-map span{
  font-size: 20px;   
  color: green;
  font-family: "poppins";
  font-weight:400;
}
.pathway-clock span{
    font-size: 20px;   
    color: green;
    font-family: "poppins";
    font-weight:400;
}

.apply-btn{
    width:150px !important;
    background: rgb(0, 150, 136) !important;
    color: #ffff !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    position: relative !important;
    left: -412px !important;
    bottom: -20px !important;
}
.apply-btn:hover{
        background-color:  #FF671F !important;
}
.apply-btn-business{
    width:150px !important;
    background: rgb(0, 150, 136) !important;
    color: #ffff !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    /* position: absolute !important; */
    float: left;
    justify-content: start !important ;
    text-decoration: none !important;
    margin-top: 20px !important;
    /* left: -412px !important;
    bottom: -20px !important; */
}
.apply-btn-business:hover{
        background-color:  #FF671F !important;
}



.card-1 {
    position: relative;
  }
  
  .card-1 {
    box-shadow: 0 0 20px rgb(0, 150, 136) ;
    border-radius: 10px;
    /* height: 190px; */
    /* width: 100%; */
padding-bottom:  80px;
padding-left: 20px;
padding-right: 20px;
    z-index: 1;
  }
  
  /* Adjust button positioning */
  .button {
    position: absolute;
    bottom: 20px; /* Adjust as needed */
    left: 50%; /* Position in the center horizontally */
    transform: translateX(-50%); /* Adjust for centering */
    z-index: 1;
  }
  .button:hover{
    background-color:  #FF671F;
  }

  
